import React from "react";

function Footer() {
  return (
    <div className="w-full bg-gray-800 py-12">
      <div className="w-8/12 mx-auto text-center">
        <p className="minerva text-lg text-white mx-5">
          Ave. Samuel Lewis, Obarrio, 56 st., Panamá Design Center, 13th Floor,
          Office 13F, Panama City, Panama
        </p>
        <p className="minerva text-lg text-white mx-5">
          &copy; 2020 Servicios Logísticos y Soluciones de Panamá S.A.
        </p>
      </div>
    </div>
  );
}

export default Footer;
